import number_format from './number-format';
import { updateTotalPrice } from './single-product';
import $ from 'jquery';

// Quantity
$(() => {
    $('body').on('click', ' .quantity .minus', function (e) {
        var $inputQty = $(this).parent().find('input.qty');
        var val = parseInt($inputQty.val());
        var step = $inputQty.attr('step');
        step = 'undefined' !== typeof step ? parseInt(step) : 1;
        if (val > 1) {
            $inputQty.val(val - step).change();
        }
    });
    $('body').on('click', ' .quantity .plus', function (e) {
        var $inputQty = $(this).parent().find('input.qty');
        var val = parseInt($inputQty.val());
        var step = $inputQty.attr('step');
        step = 'undefined' !== typeof step ? parseInt(step) : 1;

        var max = $inputQty.attr('max');
        max = 'undefined' !== typeof max ? parseInt(max) : false;

        if (max && val >= max) {
            return;
        }

        $inputQty.val(val + step).change();
    });

    // Update The Fitting Charge
    const $total = $('#product-tyre-fitting-charge-total');
    const $qtyFC = $('#product-tyre-fitting-charge-qty');
    $('.single-product div.product div.summary .quantity .qty').on(
        'change',
        () => {
            updateTotalPrice();
            // const $qty = parseFloat($('.single-product div.product div.summary .quantity .qty').val());

            // var price = parseFloat($total.attr('data-fitting-price')) * $qty;

            // $qtyFC.html(`x${$qty}`);
            // $('.show-fitting-charge').html('$' + number_format(price, 2, '.', ','));

            // price = (parseFloat($total.attr('data-product-price')) * $qty) + price;

            // $total.find('.show-total').html('$' + number_format(price, 2, '.', ','));
        }
    );

    //Toggle Fiting Chage
    $('#checkbox-fitting-charge').on('change', (e) => {
        const element = $(e.target);

        const $qty = parseFloat(
            $('.single-product div.product div.summary .quantity .qty').val()
        );
        var price = parseFloat($total.attr('data-product-price')) * $qty;

        if (element.is(':checked')) {
            price += parseFloat($total.attr('data-fitting-price')) * $qty;
        }

        $total
            .find('.show-total')
            .html('$' + number_format(price, 2, '.', ','));
    });

    if ($.fn.select2) {
        $('.select2-custom').select2();
    }
});

//Disable Keyboard “Enter” on Checkout Page
$('form.checkout').on('keypress', function (e) {
    //Enter key
    if (e.which == 13) {
        return false;
    }
});
