import $ from 'jquery';

$(() => {
  $('.finder-tool').each(function () {
    const finderTool = $(this);
    const tabs = finderTool.find('.tab-button');
    const tabsContent = finderTool.find('.finder-tool-content');

    tabs.on('click', function () {
      const tab = $(this);
      const tabId = tab.data('content');
      const tabContent = finderTool.find(`#tool-${tabId}`);

      tabs.removeClass('active');
      tabsContent.removeClass('active');

      tab.addClass('active');
      tabContent.addClass('active');
    });
  });

  $('.finder-tool .select-product-list').on('change', (e) => {
    const select = $(e.currentTarget);
    const val = select.val();
    const parent = $('.parent-' + select.data('parent'));
    var currentDataPrice = select.find('option:selected').data('price') + '';

    // if currentDataPrice dont have the word free
    if (currentDataPrice.indexOf('Free') === -1) {
      currentDataPrice = `$${currentDataPrice}`;
    }

    var addToCartUrl = '/cart/?add-to-cart=';
    addToCartUrl += val;

    parent.find('.finder-tool-price').text(currentDataPrice);
    parent.find('.finder-tool-add-to-cart').attr('href', addToCartUrl);
    parent.find('.finder-tool-make-appointment').attr('data-add-to-cart', val);
  });

  $('.finder-tool-make-appointment').on('click', (e) => {
    const val = $(e.currentTarget).data('add-to-cart');

    // check if id="data-add-to-cart" exist if not create
    if ($('#data-add-to-cart').length === 0) {
      $('body').append('<input type="hidden" id="data-ajax-add-to-cart" />');
    }

    $('#data-ajax-add-to-cart').val(val);
  });
});
