import $ from 'jquery';

$(() => {
  const admin_url = window?.sage?.ajax_url;
  const nonce = window?.sage?.nonce;
  const currentID = window?.sage?.currentUserID;

  $('body').on('click', '.handle-set-my-store', (e) => setUserStore(e));

  function setUserStore(e) {
    e.preventDefault();
    var data = {
      action: 'set_user_store',
      nonce: nonce,
      currentID: currentID,
      post_id: $(e.currentTarget).attr('data-id'),
    };

    const link = $(e.currentTarget).attr('href');

    //Construct
    $.ajax({
      type: 'post',
      url: admin_url,
      data: data,
      beforeSend: function () {
        $('.button-success-outline.my-store')
          .removeClass('button-success-outline my-store')
          .addClass('button-secondary  handle-set-my-store')
          .html('Select and Search');
        $(e.currentTarget)
          .removeClass('button-secondary  handle-set-my-store')
          .addClass('button-success-outline my-store')
          .html('This is my store');
      },
      success: function (response) {
        location.href = link;
      },
      error: function (response) {
        location.href = link;
      },
    });
  }

  var $find_tyre = $('#find-tyre-size-container');

  if ($find_tyre?.length) {
    $('html, body').animate({
      scrollTop: $find_tyre.offset().top - 30,
    });
  }
});
