document.addEventListener("DOMContentLoaded", () => {
    // Construct a selector for both relative and absolute URLs that include a hash
    const base = window.location.origin; // e.g., http://localhost:3000
    const selector = `a[href^="#"]:not(.no-anchor), a[href^="${base}/#"]:not(.no-anchor)`;
    const anchors = document.querySelectorAll(selector);

    for (let anchor of anchors) {
        anchor.addEventListener("click", (e) => {
            // Prevent default action for the anchor
            e.preventDefault();

            // Extract the hash value, considering both relative and absolute URLs
            const href = anchor.getAttribute("href");
            const hashIndex = href.indexOf("#");
            const blockID = href.substring(hashIndex + 1);

            // Use the extracted hash to perform smooth scrolling
            smoothScrollTo(blockID);
        });
    }

    // Function to handle smooth scrolling
    function smoothScrollTo(blockID) {
        const element = document.getElementById(blockID);
        if (element) {
            // Calculate the offset considering the fixed header height
            const headerOffset = 100;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition =
                elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }

    // Attempt to scroll to the hash in the URL after the entire page is loaded
    window.addEventListener("load", () => {
        if (window.location.hash) {
            // Set a slight delay to ensure all elements are fully rendered
            setTimeout(() => {
                smoothScrollTo(window.location.hash.substring(1));
            }, 100); // Adjust the timeout as necessary
        }
    });
});
