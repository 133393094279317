import { checkIfitIsReadyToApply } from './booking.js';
import $ from 'jquery';

$(() => {
    const $document = $(document);

    var isCalendarAnimating = false;

    // select day
    const selectDay = (e) => {
        $('.booking-calendar__days-item.active').removeClass('selected');
        const $this = $(e.currentTarget);
        $('#book-appointment-date').val($this.data('date'));
        $('#book-appointment-week-day').val($this.data('week-day'));
        $this.addClass('selected');

        checkIfitIsReadyToApply();
    };

    // Events

    // $calendarDaysBtn.on('click', selectDay);
    $document.on('click', '.booking-calendar__days-item.active', selectDay);

    $document.on('click', '#next-month-btn', () => {
        if (isCalendarAnimating) {
            return;
        }

        const $currentMonth = $('.month.current');
        const $nextMonth = $currentMonth.next('.month');

        if ($nextMonth.length === 0) {
            return;
        }
        isCalendarAnimating = true;

        $currentMonth.animate(
            {
                left: '-100%',
            },
            500,
            () => {
                $currentMonth.removeClass('current');
            }
        );

        $nextMonth.animate(
            {
                left: '0',
            },
            500,
            () => {
                isCalendarAnimating = false;
                $nextMonth.addClass('current');
            }
        );
    });

    $document.on('click', '#prev-month-btn', () => {
        if (isCalendarAnimating) {
            return;
        }

        const $currentMonth = $('.month.current');
        const $prevMonth = $currentMonth.prev('.month');

        if ($prevMonth.length === 0) {
            return;
        }
        isCalendarAnimating = true;

        $currentMonth.animate(
            {
                left: '100%',
            },
            500,
            () => {
                $currentMonth.removeClass('current');
            }
        );

        $prevMonth.animate(
            {
                left: '0',
            },
            500,
            () => {
                isCalendarAnimating = false;
                $prevMonth.addClass('current');
            }
        );
    });

    $document.on('click', '.booking-calendar__days-item', (e) => {
        const weekDay = $(e.currentTarget).data('week-day');
        const $select = $('#book-appointment-time');
        $select.find('option[value="12:00 PM"]').remove();
        $select.find('option[value="1:00 PM"]').remove();
        $select.find('option[value="2:00 PM"]').remove();
        $select.find('option[value="3:00 PM"]').remove();
        $select.find('option[value="4:00 PM"]').remove();

        if (weekDay != 'Saturday') {
            $select.append(
                '<option value="12:00 PM">12:00 PM</option><option value="1:00 PM">1:00 PM</option><option value="2:00 PM">2:00 PM</option><option value="3:00 PM">3:00 PM</option><option value="4:00 PM">4:00 PM</option>'
            );
        }
    });
});
