import ionRangeSlider from 'ion-rangeslider';
import { localForageDB, checkAndLoadProducts } from './localForageDB';
import $ from 'jquery';

var LIST_OF_ALL_PRODUCTS = null;
var bestInClassList = {};
var displayProducts = null;
var maxDisplayProducts = 12;

$(() => {
    const $shopContainer = $('#woo-product-loop');
    var shopType = 'tyre';

    localForageDB();

    function preloadProducts() {
        checkAndLoadProducts(shopType, function (products) {});
    }

    if (!$shopContainer.length) {
        setTimeout(() => {
            preloadProducts('tyre');
        }, 3000);
        return;
    }

    shopType = $shopContainer.data('shop-type');
    const $shopLoading = $('.woo-shop-container-loading');
    const $shopSelectFilters = $('.woo-shop-fitler-item .input');
    var $shopRadioFilters = [];
    var $shopCheckboxFilters = [];
    const $shopCheckboxesContainer = $('.woo-shop-checkboxes-container');
    const $shopRadiosContainer = $('.woo-shop-radio-container');
    var $shopRangeSliders = [];
    const $productHTML = $('#delete-after-clone .product-type-tyre').clone();
    const $shopResetFilters = $('.woo-shop-reset-filters');
    const $shopSelectResetFilter = $('.woo-shop-reset-select-filter');
    const $shopRadioResetFilter = $('.woo-shop-reset-radio-filter');
    const $selectSortBy = $('#woo-shop-sort-by');
    const $shopQtyTotal = $('#woo-shop-qty-total');
    const $noMatchMessage = $('#no-match-message');
    const $btnResetAllFilters = $('#woo-shop-reset-filters-top');
    var selectFields = [];
    var checkboxFields = [];
    var radioFields = [];
    var rangeSliderValues = [];
    var rangeSliderInstances = {};
    const shopPathParams = window.sage.shopPathParams;
    var resetShopPageInfo = window.sage.resetShopPageInfo;
    const svgStar = window.sage.svg_star;
    const svgStarHalfLeft = window.sage.svg_star_half_left;
    const svgStarHalfRight = window.sage.svg_star_half_right;
    var addingProducts = false;
    const bestInClassBadges = window.sage.bestInClassBadges;

    // Functions
    function createCheckboxFilters() {
        $shopCheckboxesContainer.each(function () {
            var $this = $(this);
            var field = $this.attr('data-id');

            checkboxFields[field] = [];
        });

        LIST_OF_ALL_PRODUCTS.forEach(function (product) {
            Object.keys(checkboxFields).forEach(function (field) {
                if (Array.isArray(product[field])) {
                    product[field].forEach(function (value) {
                        if (!checkboxFields[field].includes(value) && value) {
                            checkboxFields[field].push(value);
                        }
                    });
                } else {
                    if (
                        !checkboxFields[field].includes(product[field]) &&
                        product[field]
                    ) {
                        checkboxFields[field].push(product[field]);
                    }
                }
            });
        });

        // Sort the values
        Object.keys(checkboxFields).forEach(function (field) {
            // check if the field is a number
            if (checkboxFields[field].every((value) => !isNaN(value))) {
                checkboxFields[field] = checkboxFields[field].sort(
                    (a, b) => a - b
                );
            } else {
                checkboxFields[field] = checkboxFields[field].sort();
            }
        });

        // Remove empty values
        Object.keys(checkboxFields).forEach(function (field) {
            checkboxFields[field] = checkboxFields[field].filter(
                (value) => value !== undefined && value !== false
            );
        });

        // Remove duplicates
        Object.keys(checkboxFields).forEach(function (field) {
            checkboxFields[field] = checkboxFields[field].filter(
                (value, index, self) => self.indexOf(value) === index
            );
        });

        // Create checkboxes
        Object.keys(checkboxFields).forEach(function (field) {
            var values = checkboxFields[field];

            var $container = $(
                `.woo-shop-checkboxes-container[data-id="${field}"]`
            );

            values.forEach(function (value) {
                const slug = slugfy(value);

                var $checkbox = `<label class="woo-shop-checkbox flex items-center justify-between mb-1">
                    <span class="flex items-center">
                        <input data-label="${field}" type="checkbox"  name="${value}" value="${value}" id="${field}-${slug}" class="woo-shop-checkbox-field hidden">
                        <span class="woo-shop-checkbox-custom flex-shrink-0"><span class="checkmark"></span></span>
                        <span class="woo-shop-checkbox-label block" for="${value}">${value}</span>
                    </span>
                    <span class="woo-shop-checkbox-qty text-xs"></span>
                </label>`;

                $container.append($checkbox);
            });
        });

        $shopCheckboxFilters = $('.woo-shop-checkbox-field');
        $shopCheckboxFilters.on('change', changeCheckboxFilter);
    }

    function renameSpecialCheckboxes() {
        var specials = {
            manufacturer_id: 'manufacturer',
        };

        Object.entries(specials).forEach(([field, value]) => {
            var $checkbox = document.querySelectorAll(
                `.woo-shop-checkbox-field[data-label="${field}"]`
            );

            if ($checkbox.length) {
                Object.values($checkbox).forEach((checkbox) => {
                    let currentValue = $(checkbox).attr('value');

                    let product = LIST_OF_ALL_PRODUCTS.find((product) => {
                        return product[field] == currentValue;
                    });

                    if (product) {
                        let label = checkbox.parentElement.querySelector(
                            '.woo-shop-checkbox-label'
                        );

                        label.innerText = product[value];
                    }
                });
            }
        });
    }

    function createRadioFilters() {
        $shopRadiosContainer.each(function () {
            var $this = $(this);
            var field = $this.attr('data-id');

            radioFields[field] = [];
        });

        LIST_OF_ALL_PRODUCTS.forEach(function (product) {
            Object.keys(radioFields).forEach(function (field) {
                if (Array.isArray(product[field])) {
                    product[field].forEach(function (value) {
                        if (!radioFields[field].includes(value) && value) {
                            radioFields[field].push(value);
                        }
                    });
                } else {
                    if (
                        !radioFields[field].includes(product[field]) &&
                        product[field]
                    ) {
                        radioFields[field].push(product[field]);
                    }
                }
            });
        });

        // Sort the values
        Object.keys(radioFields).forEach(function (field) {
            // check if the field is a number
            if (radioFields[field].every((value) => !isNaN(value))) {
                radioFields[field] = radioFields[field].sort((a, b) => a - b);
            } else {
                radioFields[field] = radioFields[field].sort();
            }
        });

        // Remove empty values
        Object.keys(radioFields).forEach(function (field) {
            radioFields[field] = radioFields[field].filter(
                (value) => value !== undefined && value !== false
            );
        });

        // Remove duplicates
        Object.keys(radioFields).forEach(function (field) {
            radioFields[field] = radioFields[field].filter(
                (value, index, self) => self.indexOf(value) === index
            );
        });

        // Create radio
        Object.keys(radioFields).forEach(function (field) {
            var values = radioFields[field];

            var $container = $(`.woo-shop-radio-container[data-id="${field}"]`);

            values.forEach(function (value) {
                var $radio = `<label class="woo-shop-radio flex items-center justify-between mb-1">
                    <span class="flex items-center">
                        <input data-label="${field}" type="radio"  name="${field}" value="${value}" id="${field}-${value}" class="woo-shop-radio-field hidden">
                        <span class="woo-shop-radio-custom"><span class="checkmark"></span></span>
                        <span class="woo-shop-radio-label block" for="${value}">${value}</span>
                    </span>
                    <span class="woo-shop-checkbox-qty text-xs"></span>
                </label>`;
                $container.append($radio);
            });
        });

        $shopRadioFilters = $('.woo-shop-radio-field');

        $shopRadioFilters.on('change', changeRadioFilter);
    }

    function updateSlider(field, from, to) {
        if (rangeSliderInstances[field]) {
            rangeSliderInstances[field].update({
                from: from,
                to: to,
            });

            rangeSliderValues[field]['min'] = from;
            rangeSliderValues[field]['max'] = to;
        }
    }

    function createRangeSliders(displayProducts) {
        $shopRangeSliders = $('.woo-shop-range-slider');

        $shopRangeSliders.each(function () {
            var $this = $(this);
            var field = $this.data('id');
            var gridNum = $this.data('grid-num');

            var min = displayProducts.reduce((min, product) => {
                return parseInt(product[field]) < min
                    ? parseInt(product[field])
                    : min;
            }, parseInt(displayProducts[0][field]));

            var max = displayProducts.reduce((max, product) => {
                return parseInt(product[field]) > max
                    ? parseInt(product[field])
                    : max;
            }, parseInt(displayProducts[0][field]));

            rangeSliderValues[field] = {
                min: min,
                max: max,
                defaultMin: min,
                defaultMax: max,
            };

            $this.ionRangeSlider({
                type: 'double',
                min: min,
                max: max,
                from: min,
                to: max,
                grid: true,
                grid_num: gridNum,
                skin: 'round',
                onFinish: function (data) {
                    rangeSliderValues[field] = {
                        min: data.from,
                        max: data.to,
                    };

                    handleProductsToDisplayChange();
                },
            });

            // Store the slider instance for later updates
            var sliderInstance = $this.data('ionRangeSlider');
            rangeSliderInstances[field] = sliderInstance;
        });
    }

    function mapSelectFields() {
        $shopSelectFilters.each(function () {
            var $this = $(this);
            var field = $this.attr('data-label');

            selectFields.push(field);
        });
    }

    function handleSelectOptionsUpdate(selectsFiltering) {
        var options = [];

        // Organized them
        selectFields.forEach(function (field) {
            var values = displayProducts.flatMap((product) => {
                if (Array.isArray(product[field])) {
                    return [...product[field]];
                } else {
                    return [product[field]];
                }
            });

            values = values.filter(
                (value, index, self) =>
                    self.indexOf(value) === index &&
                    value !== undefined &&
                    value !== false
            );

            if (values.every((value) => !isNaN(value))) {
                values = values.sort((a, b) => a - b);
            } else {
                values = values.sort();
            }

            if (values && values.length > 0) {
                options[field] = values;
            }
        });

        if (
            (options['profile'] &&
                options['profile'].includes('80') &&
                options['profile'].length > 2) ||
            (options['profile'] &&
                options['profile'].includes('80') &&
                selectsFiltering['profile'] &&
                selectsFiltering['profile'].includes('R'))
        ) {
            options['profile'].unshift('R');
        }

        // Populate select options
        for (var field in options) {
            var values = options[field];

            var $select = $(`select[data-label="${field}"]`);

            // delete all options except the first one
            $select.find('option').not(':first').remove();

            if ($select.length) {
                values.forEach(function (value) {
                    var $option = $('<option>', {
                        value: value,
                        text: value,
                    });

                    $select.append($option);
                });
            }
        }

        // Re select the options
        for (var field in selectsFiltering) {
            var value = selectsFiltering[field];

            $(`select[data-label="${field}"]`).val(value);
        }

        // Fixing R on Profile
        if (selectsFiltering['profile'] && selectsFiltering['profile'] == 80) {
            // check if has the option R
            if (options['profile'].includes('R')) {
                $(`select[data-label="profile"]`).val('R');
            }
        }
    }

    function handleCheckboxOptionsUpdate(fieldQty) {
        for (var field in fieldQty) {
            var values = fieldQty[field];

            var $container = $(
                `.woo-shop-checkboxes-container[data-id="${field}"]`
            );

            if (!$container.length) {
                $container = $(`.woo-shop-radio-container[data-id="${field}"]`);
            }
            if ($container.length) {
                for (var value in values) {
                    var qty = values[value];

                    var $checkbox = $container.find(`input[value="${value}"]`);

                    if ($checkbox.length) {
                        $checkbox
                            .parent()
                            .siblings('.woo-shop-checkbox-qty')
                            .text(qty);

                        if (qty == 0) {
                            $checkbox.parent().parent().addClass('hidden');
                        } else {
                            $checkbox.parent().parent().removeClass('hidden');
                        }
                    }
                }
            }
        }
    }

    function handleReviewsCheckboxesUpdate() {
        const reviews = {
            5: 0,
            4: 0,
            3: 0,
            2: 0,
            1: 0,
        };

        displayProducts.forEach((product) => {
            if (product.reviews_avarage_rating_rounded > 0) {
                reviews[product.reviews_avarage_rating_rounded]++;
            }
        });

        for (const [rating, qty] of Object.entries(reviews)) {
            const $label = $(
                `#woo-shop-checkbox-expert-review-${rating} .woo-shop-checkbox-qty`
            );

            if ($label.length) {
                $label.text(qty);
            }
        }
    }

    function addTyresToShopContainer() {
        if (addingProducts) {
            return;
        }

        addingProducts = true;

        let products = displayProducts;
        products = filterByReviews(products);

        if (products.length === 0) {
            $noMatchMessage.fadeIn(100);
            addingProducts = false;

            return;
        } else {
            $noMatchMessage.fadeOut(100);
        }

        const allProductsOnStageIds = $(
            '.woo-shop-container .product-loop-item'
        )
            .map((_, item) => parseInt($(item).data('post-id')))
            .get();

        products = products
            .filter(
                (product) =>
                    !allProductsOnStageIds.includes(parseInt(product.post_id))
            )
            .slice(0, maxDisplayProducts);

        if (products.length === 0) {
            addingProducts = false;
            return; // Exit if no new products to display
        }

        products.forEach((product) => {
            let $product = $productHTML.clone();

            $product.attr('data-post-id', product.post_id);

            $product
                .find('.product-type-tyre__link')
                .attr('href', product.link);

            $product
                .find('.product-type-tyre__image_url')
                .attr('src', product.image_url)
                .attr('alt', product.title);

            $product
                .find('.product-type-tyre__manufacturer_image')
                .attr('src', product.manufacturer_image)
                .attr('alt', product.manufacturer);

            $product
                .find('.product-type-tyre__model_title')
                .text(product.model);

            $product.find('.product-type-tyre__specs').text(product.subtitle);

            $product.find('.product-type-tyre__price').html(product.price);

            if (product.reviews_avarage_rating > 0) {
                $product
                    .find('.product-type-tyre__reviews')
                    .removeClass('hidden')
                    .addClass('flex');
                $product
                    .find('.product-type-tyre__reviews--total')
                    .text(`(${product.reviews_count})`);

                // Generate the stars
                let rating = parseFloat(product.reviews_avarage_rating);
                rating = Math.min(Math.max(rating, 0), 5);

                const fullStars = Math.floor(rating);
                const halfStar = rating - fullStars >= 0.5 ? 1 : 0;
                const emptyStars = 5 - fullStars - halfStar;

                let stars =
                    '<div class="comment-ratings flex items-center gap-1 text-slate-200">';
                for (let i = 0; i < fullStars; i++) {
                    stars += `<span class="color-mainBrand">${svgStar}</span>`;
                }

                if (halfStar) {
                    stars += `<span class="relative  flex items-center 2-4 flex-shrink-0">
                        ${svgStarHalfLeft}
                        ${svgStarHalfRight}
                        </span>
                    `;
                }

                for (let i = 0; i < emptyStars; i++) {
                    stars += `<span class="text-slate-400">${svgStar}</span>`;
                }

                stars += '</div>';

                $product.find('.product-type-tyre__reviews').prepend(stars);
            }

            if (product.promotion) {
                const img = document.createElement('img');
                img.src = product.promotion_badge;
                img.alt = product.promotion_name;
                img.className =
                    'absolute bottom-0 right-0 w-20 h-20 object-contain object-center drag-none';

                $product
                    .find('.product-type-tyre__image_container')
                    .append(img);
            }

            if (product?.discontinued_badge_url) {
                const img = document.createElement('img');
                img.src = product.discontinued_badge_url;
                img.alt = product.promotion_name;
                img.className =
                    'absolute top-0 left-0 w-full h-full object-contain object-center drag-none';

                $product
                    .find(
                        '.product-type-tyre__image_container .product-type-tyre__link'
                    )
                    .append(img);
            }

            if (product.badges) {
                const div = document.createElement('div');
                div.className = 'absolute bottom-0 left-0';

                for (let [, badge] of Object.entries(product.badges)) {
                    const img = document.createElement('img');
                    img.src = badge.src;
                    img.className =
                        'w-12 h-12 object-contain object-center drag-none mt-1';
                    img.alt = badge.alt;

                    div.appendChild(img);
                }

                $product
                    .find(
                        '.product-type-tyre__image_container .product-type-tyre__link'
                    )
                    .append(div);
            }

            // check if product is best in class
            if (product.bestInClass) {
                $product.addClass(`best-in-class`);

                if (bestInClassBadges[product.bestInClass]) {
                    const img = document.createElement('img');
                    img.src = bestInClassBadges[product.bestInClass];
                    img.alt = 'Best in Class';
                    img.className =
                        'absolute  top-0 right-0 w-full aspect-ratio object-contain object-center drag-none';

                    $product
                        .find(
                            '.product-type-tyre__image_container .product-type-tyre__link'
                        )
                        .append(img);
                }
            }

            $shopContainer.append($product);
        });

        addingProducts = false;
    }

    function handleProductsToDisplayChange() {
        // Check selects
        var selectsFiltering = {};

        $shopSelectFilters.each(function () {
            var $this = $(this);
            var field = $this.data('label');
            var value = $this.val();

            if (value) {
                selectsFiltering[field] = [value];

                if (value == 'R') {
                    selectsFiltering[field] = [value, '80'];
                }
            }
        });

        // Check checkboxes
        var checkboxesFiltering = {};

        $shopCheckboxFilters.each(function () {
            var $this = $(this);
            var field = $this.data('label');
            var value = $this.is(':checked');

            if (value) {
                if (!checkboxesFiltering[field]) {
                    checkboxesFiltering[field] = [];
                }

                checkboxesFiltering[field].push($this.val());
            }
        });

        // Check radios
        var radiosFiltering = {};

        $shopRadioFilters.each(function () {
            var $this = $(this);
            var field = $this.data('label');

            if ($this.is(':checked')) {
                radiosFiltering[field] = [$this.val()];
            }
        });

        var combinedFiltering = {
            ...selectsFiltering,
            ...checkboxesFiltering,
            ...radiosFiltering,
        };

        // Check if there are any filters
        if (
            Object.keys(combinedFiltering).length > 0
            // ||
            // loadIndexRange.min !== loadIndexRange.defaultMin ||
            // loadIndexRange.max !== loadIndexRange.defaultMax
        ) {
            $btnResetAllFilters.fadeIn(100);
        } else {
            $btnResetAllFilters.fadeOut(100);
        }

        const { filteredProducts, fieldQty } = filterAndCountProducts(
            LIST_OF_ALL_PRODUCTS,
            combinedFiltering,
            { ...checkboxFields, ...radioFields }
        );

        displayProducts = filteredProducts;

        $shopContainer.html('');

        // Selects
        handleSelectOptionsUpdate(selectsFiltering);

        // Checkboxed
        handleCheckboxOptionsUpdate(fieldQty);

        handleReviewsCheckboxesUpdate();

        displayProducts = sortProducts(displayProducts);

        displayProducts = setBestInClass(combinedFiltering, displayProducts);

        addTyresToShopContainer();

        updateProductQtyTotal();
    }

    function filterAndCountProducts(products, filters, countField) {
        let fieldQty = {};

        // set all the field values to 0
        Object.entries(countField).forEach(([field, values]) => {
            fieldQty[field] = {};

            values.forEach((value) => {
                fieldQty[field][value] = 0;
            });
        });

        const filteredProducts = products.filter((product) => {
            // Assume product passes the filters until proven otherwise
            let passesFilters = Object.entries(filters).every(
                ([field, filterValues]) => {
                    if (Array.isArray(product[field])) {
                        return product[field].some((productValue) =>
                            filterValues.includes(productValue)
                        );
                    } else {
                        return filterValues.includes(product[field]);
                    }
                }
            );

            // Check Range Sliders
            if (passesFilters) {
                if (rangeSliderValues) {
                    for (var field in rangeSliderValues) {
                        var value = parseInt(product[field]);

                        if (
                            value < rangeSliderValues[field].min ||
                            value > rangeSliderValues[field].max
                        ) {
                            passesFilters = false;
                            break;
                        }
                    }
                }
            }

            // Counting
            if (passesFilters) {
                Object.keys(fieldQty).forEach((countField) => {
                    // Check if the product has a value for the count field and increment appropriately
                    let value = product[countField];
                    if (Array.isArray(value)) {
                        value.forEach((val) => {
                            if (fieldQty[countField][val] !== undefined) {
                                fieldQty[countField][val]++;
                            }
                        });
                    } else {
                        if (fieldQty[countField][value] !== undefined) {
                            fieldQty[countField][value]++;
                        }
                    }
                });
            }

            return passesFilters; // Return the result of the filter check
        });

        return { filteredProducts, fieldQty };
    }

    function filterByReviews(products) {
        const reviews = {
            5: false,
            4: false,
            3: false,
            2: false,
            1: false,
        };

        for (const [rating, qty] of Object.entries(reviews)) {
            const $checkbox = $(`#expert_reviews-${rating}`);

            if ($checkbox.length) {
                reviews[rating] = $checkbox.is(':checked');
            }
        }

        // if all are false return all products
        if (Object.values(reviews).every((value) => !value)) {
            return products;
        }

        products = products.filter((product) => {
            return reviews[product.reviews_avarage_rating_rounded];
        });

        return products;
    }

    function sortProducts(products) {
        const sortBy = $selectSortBy.val();

        if (sortBy === 'price-low-to-high') {
            return products.sort((a, b) => a.price_plain - b.price_plain);
        } else if (sortBy === 'price-high-to-low') {
            return products.sort((a, b) => b.price_plain - a.price_plain);
        } else if (sortBy === 'name-z-to-a') {
            return products.sort((a, b) => b.title.localeCompare(a.title));
        } else if (sortBy === 'name-a-to-z' || !products[0]?.tier) {
            return products.sort((a, b) => a.title.localeCompare(b.title));
        }

        return products.sort((a, b) => a.tier.localeCompare(b.tier));
    }

    function decodeHtmlEntities(str) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    }

    function checkIfHasParameters() {
        // get all parameters
        var url = new URL(window.location.href);

        var updatePage = false;

        var checks = selectFields;

        checks.push('promotion-name');
        checks.push('promotion');

        // check if there are any parameters based on fields
        checks.forEach(function (field) {
            var value = url.searchParams.get(field);

            if (value) {
                updatePage = true;
                $(`select[data-label="${field}"]`).val(value);
            }

            if (field == 'promotion' || field == 'promotion-name') {
                value = url.searchParams.get('promotion-name');

                if (!value) {
                    return;
                }

                if (value) {
                    updatePage = true;
                    // checkbox
                    $(`#${value}`).prop('checked', true);
                }
            }
        });

        // From Path
        if (shopPathParams) {
            for (const key in shopPathParams) {
                if (shopPathParams.hasOwnProperty(key)) {
                    const value = shopPathParams[key]['value'];
                    const type = shopPathParams[key]['type'];

                    if (type == 'select') {
                        $(`select[data-label="${key}"]`).on(
                            'change',
                            resertPageToInitialState
                        );
                        $(`select[data-label="${key}"]`).val(value);

                        $(`select[data-label="${key}"]`)
                            .siblings('.woo-shop-label-filter')
                            .find('.woo-shop-reset-select-filter')
                            .fadeIn(100);

                        openAccordion(`woo-shop-fitler-${key}`);
                    } else if (type == 'checkbox') {
                        $(`#${key}-${slugfy(value)}`).on(
                            'change',
                            resertPageToInitialState
                        );
                        $(`#${key}-${slugfy(value)}`).prop('checked', true);
                        openAccordion(`${key}-${slugfy(value)}`);
                    } else if (type == 'radio') {
                        $(`input[name="${key}"][value="${value}"]`).prop(
                            'checked',
                            true
                        );
                        $(`#woo-shop-reset-${key}`).fadeIn(100);
                        $(`#woo-shop-reset-${key}`).on(
                            'click',
                            resertPageToInitialState
                        );

                        openAccordion(`${key}-${value}`);
                    } else if (type == 'range') {
                        updateSlider(key, value, value);
                        openAccordion(key);
                    }
                }

                updatePage = true;
            }
        }

        if (updatePage) {
            handleProductsToDisplayChange();
        }
    }

    function openAccordion(id) {
        let item = document.getElementById(id);
        let accordion = findAncestorWithClass(
            item,
            'accordion-filter-container'
        );

        if (accordion) {
            let accordionHeading =
                accordion.querySelector('.accordion-heading');

            // check if has class active
            if (!accordionHeading.classList.contains('active')) {
                accordionHeading.click();
            }
        }
    }

    function findAncestorWithClass(element, className) {
        var maxDepth = 20;

        while (element && maxDepth > 0) {
            if (element.classList && element.classList.contains(className)) {
                return element;
            }
            element = element.parentElement;

            maxDepth--;
        }
        return null;
    }

    function checkIfHasPromotions() {
        // check if any product has promotion if not hide the promotion filter
        var hasPromotion = false;

        LIST_OF_ALL_PRODUCTS.forEach(function (product) {
            if (product.promotion) {
                hasPromotion = true;
            }
        });

        if (!hasPromotion) {
            $('#accordion-promotions').remove();
        }
    }

    function slugfy(string) {
        let processedString = string.toString();
        if (processedString.normalize) {
            processedString = processedString
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
        return processedString
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/&/g, '-and-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-');
    }

    function showFilters() {
        $('html').addClass('no-scroll');
        $('.woo-shop-fitler').addClass('show');
    }

    function hideFilters() {
        $('.woo-shop-fitler').removeClass('show');
        $('html').removeClass('no-scroll');
    }

    function changeSelectFilter(e) {
        const $this = $(this);

        const $resetFilter = $this
            .siblings('.woo-shop-label-filter')
            .find('.woo-shop-reset-select-filter');

        $resetFilter.fadeIn(100);

        handleProductsToDisplayChange();
    }

    function changeCheckboxFilter(e) {
        handleProductsToDisplayChange();
    }

    function changeRadioFilter(e) {
        const $this = $(this);

        const $resetFilter = $(`#woo-shop-reset-${$this.data('label')}`);

        $resetFilter.fadeIn(100);

        handleProductsToDisplayChange();
    }

    function changeCheckboxReview(e) {
        handleProductsToDisplayChange();
    }

    function resetSelectFilter(e) {
        e.preventDefault();

        const $this = $(this);

        const $select = $this.parent().siblings('select');

        $select.val('');

        $this.fadeOut(100);

        handleProductsToDisplayChange();
    }

    function resetRadioFilter(e) {
        e.preventDefault();

        const $this = $(this);
        const name = $this.data('label');

        const $radios = $(`.woo-shop-radio-field[name="${name}"]`);

        $radios.prop('checked', false);

        $this.fadeOut(100);

        handleProductsToDisplayChange();
    }

    function resetAllFilters() {
        displayProducts = LIST_OF_ALL_PRODUCTS;
        $shopSelectFilters.val('');
        $shopCheckboxFilters.prop('checked', false);
        $shopRadioFilters.prop('checked', false);

        // reset slider load index
        // $('.woo-shop-range-slider[data-id="load_index"]')
        //     .data("ionRangeSlider")
        //     .reset();

        handleProductsToDisplayChange();

        $btnResetAllFilters.fadeOut(100);
        $shopSelectResetFilter.fadeOut(100);

        resertPageToInitialState();
    }

    function updateProductQtyTotal() {
        const total = displayProducts.length;

        $shopQtyTotal.text(total);
    }

    function fixHtmlEnties(products) {
        return products.map((product) => {
            const fixedProduct = {};

            for (const key in product) {
                if (key == 'reviews') {
                    fixedProduct[key] = product[key];
                } else if (key == 'badges') {
                    fixedProduct[key] = product[key];
                    continue;
                } else if (Array.isArray(product[key])) {
                    fixedProduct[key] = product[key].map((item) => {
                        if (item) {
                            return decodeHtmlEntities(item);
                        }

                        return item;
                    });
                } else if (product[key]) {
                    fixedProduct[key] = decodeHtmlEntities(product[key]);
                }
            }

            return fixedProduct;
        });
    }

    function setBestInClass(combinedFiltering, displayProducts) {
        // clean bestInClass from the products
        displayProducts = displayProducts.map((product) => {
            delete product.bestInClass;
            return product;
        });

        if (Object.keys(bestInClassList).length) {
            // check if combinedFiltering has width, profile and rim keys
            if (
                combinedFiltering['width'] &&
                combinedFiltering['profile'] &&
                combinedFiltering['rim']
            ) {
                let currentWidth = combinedFiltering['width'][0];
                let currentProfile = combinedFiltering['profile'][0];

                currentProfile = currentProfile == 'R' ? 80 : currentProfile;

                let currentRim = combinedFiltering['rim'][0];
                let bestInClassProducts = Object.values(bestInClassList).filter(
                    (product) =>
                        product.width == currentWidth &&
                        product.profile == currentProfile &&
                        product.rim == currentRim
                );
                let prioritizedProducts = [];

                const findProductInDisplay = (postId) =>
                    displayProducts.find(
                        (p) => parseInt(p.post_id) === parseInt(postId)
                    );

                // Collect 'best', 'better', and 'good' products if they exist
                bestInClassProducts.forEach((bestProduct) => {
                    if (bestProduct.best) {
                        let productInDisplay = findProductInDisplay(
                            bestProduct.best.post_id
                        );
                        if (productInDisplay) {
                            prioritizedProducts.push({
                                ...productInDisplay,
                                bestInClass: 'best',
                            });
                        }
                    }
                    if (bestProduct.better) {
                        let productInDisplay = findProductInDisplay(
                            bestProduct.better.post_id
                        );
                        if (productInDisplay) {
                            prioritizedProducts.push({
                                ...productInDisplay,
                                bestInClass: 'better',
                            });
                        }
                    }
                    if (bestProduct.good) {
                        let productInDisplay = findProductInDisplay(
                            bestProduct.good.post_id
                        );
                        if (productInDisplay) {
                            prioritizedProducts.push({
                                ...productInDisplay,
                                bestInClass: 'good',
                            });
                        }
                    }
                });

                // Sort prioritizedProducts by good, better, best
                const priorityOrder = { good: 1, better: 2, best: 3 };
                prioritizedProducts.sort(
                    (a, b) =>
                        priorityOrder[a.bestInClass] -
                        priorityOrder[b.bestInClass]
                );

                // Merge best-in-class products at the front of displayProducts

                if (prioritizedProducts.length) {
                    const sortBy = $selectSortBy.val();
                    if (sortBy === 'popularity') {
                        displayProducts = [
                            ...prioritizedProducts.filter((p) =>
                                displayProducts.find(
                                    (d) => d.post_id == p.post_id
                                )
                            ),
                            ...displayProducts.filter(
                                (d) =>
                                    !prioritizedProducts.some(
                                        (b) => b.post_id == d.post_id
                                    )
                            ),
                        ];
                    } else {
                        // add the bestInClass to the product
                        displayProducts = displayProducts.map((product) => {
                            const bestInClassProduct = prioritizedProducts.find(
                                (p) => p.post_id == product.post_id
                            );

                            if (bestInClassProduct) {
                                product.bestInClass =
                                    bestInClassProduct.bestInClass;
                            }

                            return product;
                        });
                    }
                }
            }
        }

        return displayProducts;
    }

    function updateUI(products, bestInClass = false) {
        LIST_OF_ALL_PRODUCTS = fixHtmlEnties(products);
        displayProducts = LIST_OF_ALL_PRODUCTS;
        bestInClassList = bestInClass;

        // Fix Image on Oil
        if (shopType == 'oil') {
            $productHTML
                .find('.product-type-tyre__manufacturer_image')
                .removeClass('h-10 w-36')
                .addClass('h-5 w-16');
        }

        mapSelectFields();
        createCheckboxFilters();
        createRadioFilters();
        createRangeSliders(displayProducts);
        renameSpecialCheckboxes();
        handleReviewsCheckboxesUpdate();
        handleProductsToDisplayChange();
        $('#delete-after-clone').remove();

        checkIfHasParameters();

        checkIfHasPromotions();

        observer.observe($('#load-more')[0]);

        $shopLoading.fadeOut(300);
    }

    function resertPageToInitialState() {
        if (!resetShopPageInfo) {
            return;
        }

        const $title = $('#shop-title');
        const $paragraph = $('#shop-paragraph');
        const $breadcrumb = $('#page-header-breadcrumb');

        $title.addClass('text-white');
        $paragraph.addClass('text-white');
        $('#page-header-breadcrumb *')
            .addClass('text-white')
            .removeClass('color-darkAccent');

        $title.find('.placeholder-skeleton').removeClass('hidden');
        $paragraph.find('.placeholder-skeleton').removeClass('hidden');
        $breadcrumb.find('.placeholder-skeleton').removeClass('hidden');

        setTimeout(() => {
            $title.find('.placeholder-skeleton').remove();
            $paragraph.find('.placeholder-skeleton').remove();
            $breadcrumb.find('.placeholder-skeleton').remove();

            $title.removeClass('text-white');
            $paragraph.removeClass('text-white');

            $title.html(resetShopPageInfo.title);
            $paragraph.html(resetShopPageInfo.paragraph);
            $breadcrumb.html(
                `<span class="color-darkAccent ">${resetShopPageInfo.breadcrumb}</span><span class="mx-1 md:mx-2  md:text-xl  color-lightAccent">/</span>`
            );

            // set the url to resetShopPageInfo.url
            window.history.pushState({}, '', resetShopPageInfo.url);

            //Remove content
            $('.shop-page-body-content').remove();
            $('.popular-product-range').remove();
            $('.promotion-product-range').remove();
            $('.model-product-range').remove();
            $('.best-in-class-product-range').remove();

            resetShopPageInfo = false;
        }, 1000);
    }

    function init() {
        checkAndLoadProducts(
            shopType,
            function (products, bestInClass = false) {
                updateUI(products, bestInClass);
            }
        );
    }

    // Actions
    $('.woo-shop-view-tyres').on('click', hideFilters);
    $('.woo-shop-show-filters').on('click', showFilters);
    $shopSelectResetFilter.on('click', resetSelectFilter);
    $shopRadioResetFilter.on('click', resetRadioFilter);
    $shopResetFilters.on('click', resetAllFilters);
    $shopSelectFilters.on('change', changeSelectFilter);
    $('.woo-shop-review-field').on('change', changeCheckboxReview);
    $selectSortBy.on('change', handleProductsToDisplayChange);

    // Observers
    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    addTyresToShopContainer();
                }
            });
        },
        {
            rootMargin: '200px',
        }
    );

    // Start
    init();
});
