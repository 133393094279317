import $ from 'jquery';

$(() => {
  var $menu = $('#main-slide-menu');

  const openMenu = () => {
    $menu.css('display', 'block');

    $menu.find('.background').fadeIn('fast');
    $menu.find('.main-slide-menu-container').css('left', 0);
  };

  const closeMenu = () => {
    $menu.find('.background').fadeOut('fast');
    $menu.find('.main-slide-menu-container').css('left', '100%');

    setTimeout(() => {
      $menu.css('display', 'none');
    }, 500);
  };

  $('.open-slide-menu').on('click', openMenu);
  $('.close-slide-menu').on('click', closeMenu);
});
