import { closeModal } from './modal.js';
import $ from 'jquery';

export const checkoutFillRequiredUserFields = () => {
  if (!$('body').hasClass('woocommerce-checkout')) {
    // closeModal();
    return;
  }

  if (Cookies.get('storeTitle')) {
    $('#user_store_id').val(Cookies.get('storeID'));
  }

  if (Cookies.get('bookingDate') && Cookies.get('bookingTime')) {
    $('#user_booking_date').val(
      Cookies.get('bookingDate') + ' at ' + Cookies.get('bookingTime')
    );
  }

  // const $addressField = $("#billing_address_1");
  // const $cityField = $("#billing_city");
  // const $stateField = $("#billing_state");
  // const $zipField = $("#billing_postcode");

  // const address = Cookies.get("wp_user_location_billing_address_1");
  // const city = Cookies.get("wp_user_location_billing_city");
  // const state = Cookies.get("wp_user_location_billing_state");
  // const zip = Cookies.get("wp_user_location_billing_postcode");

  // var displayFields = false;

  // if (address && address.indexOf("undefined") == -1) {
  //     $addressField.val(address);
  // } else {
  //     displayFields = true;
  // }

  // if (city && city.indexOf("undefined") == -1) {
  //     $cityField.val(city);
  // } else {
  //     displayFields = true;
  // }

  // if (state && state.indexOf("undefined") == -1) {
  //     if ($stateField.find(`option[value="${state}"]`).length) {
  //         $stateField.val(state).trigger("change");
  //     } else if ($stateField.find(`option:contains("${state}")`).length) {
  //         $stateField
  //             .val($stateField.find(`option:contains("${state}")`).val())
  //             .trigger("change");
  //     }
  // } else {
  //     displayFields = true;
  // }
  // if (zip && zip.indexOf("undefined") == -1) {
  //     $zipField.val(zip);
  // } else {
  //     displayFields = true;
  // }

  // if (displayFields) {
  //     $("#billing_address_1_field").fadeIn();
  //     $("#billing_city_field").fadeIn();
  //     $("#billing_state_field").fadeIn();
  //     $("#billing_postcode_field").fadeIn();
  // }
};

$(() => {
  checkoutFillRequiredUserFields();
});
