document.addEventListener('DOMContentLoaded', () => {
    const toolBox = document.getElementById('toolbox');

    if (!toolBox) {
        return;
    }

    // Tabs
    const tabs = toolBox.querySelectorAll('.toolbox-tab-item');

    tabs.forEach((tab) => {
        tab.addEventListener('click', () => {
            const tabId = tab.dataset.content;
            const tabContent = document.getElementById(`tool-${tabId}`);
            const contents = toolBox.querySelectorAll('.toolbox-content-item');

            tabs.forEach((t) => t.classList.remove('active'));

            contents.forEach((c) => c.classList.remove('active'));
            tabContent.classList.add('active');
        });
    });

    // Select Price Change
    const selectProductList = toolBox.querySelectorAll(
        '.toolbox-content-product-select'
    );

    selectProductList.forEach((select) => {
        select.addEventListener('change', (e) => {
            const val = e.target.value;
            const parent = document.querySelector(
                `#tool-${e.target.dataset.parent}`
            );

            var price = e.target.options[e.target.selectedIndex].dataset.price;

            const addToCartUrl = `/cart/?add-to-cart=${val}`;
            const addToCart = parent.querySelector('.toolbox-add-to-cart');
            const makeAppointment = parent.querySelector(
                '.toolbox-make-appointment'
            );

            parent.querySelector(`.toolbox-display-price`).innerHTML = price;

            if (addToCart) {
                addToCart.setAttribute('href', addToCartUrl);
            }

            if (makeAppointment) {
                makeAppointment.dataset.addToCart = val;
            }
        });
    });
});
