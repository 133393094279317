import tippy from 'tippy.js';
import $ from 'jquery';

$(() => {
  tippy('.use-tippy-tooltip', {
    animation: 'shift-away',
    theme: 'legend',
    content: (reference) => reference.getAttribute('data-tooltip'),
  });
});
