import $ from 'jquery';

export const openModal = (title) => {
  const $modalTitle = $('#modal-container-header-title');
  const $modalContent = $('#modal-container-content');
  const $modalWrapper = $('#modal-container-wrapper');
  const $modalLoading = $('#modal-container-loading').html();

  $modalTitle.html(title);
  $modalContent.html($modalLoading);

  setTimeout(() => {
    $modalWrapper.removeClass('-translate-y-8 opacity-0');
  }, 300);

  $('#modal-container').fadeIn('fast');
};

export const closeModal = () => {
  const $modalContainer = $('#modal-container');
  const $modalContent = $('#modal-container-content');
  const $modalWrapper = $('#modal-container-wrapper');

  $modalContainer.fadeOut('fast', () => {
    $modalContent.html('');
    $modalWrapper.addClass('-translate-y-8 opacity-0');
  });
};

$('.on-close-modal').on('click', closeModal);
