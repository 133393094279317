//
import $ from 'jquery';

var locations = [];
var markers = [];
var selectedServices = [];
var map = false;
var infowindow = false;
var pinIcon = false;
var latitudeGlobal = false;
var longitudeGlobal = false;

window.initGoogleMap = async () => {
    // 1) Dynamically import the new libraries
    const { Autocomplete } = await google.maps.importLibrary('places');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => {
            handleGoogleMap({ Autocomplete, AdvancedMarkerElement });
        });
    } else {
        handleGoogleMap({ Autocomplete, AdvancedMarkerElement });
    }
};

function handleGoogleMap({ Autocomplete, AdvancedMarkerElement }) {
    const admin_url = window?.sage?.ajax_url;
    const nonce = window?.sage?.nonce;
    const currentUserID = window?.sage?.currentUserID;
    const userIP = window?.sage?.getTheUserIP;

    //Set User Location
    $('#request-user-location').on('click', () => {
        navigator.geolocation.getCurrentPosition(successNavigatorPos, error, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        });
    });

    function successNavigatorPos(pos) {
        const crd = pos.coords;

        latitudeGlobal = crd.latitude;
        longitudeGlobal = crd.longitude;
        getClosestStore(latitudeGlobal, longitudeGlobal);
    }

    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    //Create The Map
    if (document.getElementById('find-your-local-input')) {
        var autocomplete = new Autocomplete(
            document.getElementById('find-your-local-input'),
            {
                componentRestrictions: {
                    country: 'nz',
                },
            }
        );

        autocomplete.addListener('place_changed', () => {
            // if (!autocomplete || typeof autocomplete.getPlace !== 'function') {
            //     console.log('no autocomplete');
            //     return;
            // }

            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert('Please select one address!');
                return;
            }

            latitudeGlobal = place.geometry.location.lat();
            longitudeGlobal = place.geometry.location.lng();

            getClosestStore(latitudeGlobal, longitudeGlobal);
        });
    }

    //Get Closest Store
    function getClosestStore(latitude, longitude) {
        var closestLocation = { distance: false, marker: false };
        markers.forEach((marker, key) => {
            let distance = filterLocations(
                latitude,
                longitude,
                marker.position.lat,
                marker.position.lng
            );
            if (key != 0) {
                if (distance < closestLocation.distance) {
                    closestLocation.distance = distance;
                    closestLocation.marker = marker;
                }
            } else {
                closestLocation.distance = distance;
                closestLocation.marker = marker;
            }
        });

        if (closestLocation.marker) {
            map.setZoom(13);
            map.setCenter(
                new google.maps.LatLng(
                    parseFloat(closestLocation.marker.position.lat),
                    parseFloat(closestLocation.marker.position.lng)
                )
            );
            openDialogMarkerInfo(closestLocation.marker);
        }
    }

    //Get Stores
    var data = {
        action: 'get_all_stores_location',
        nonce: nonce,
    };

    $.ajax({
        type: 'post',
        url: admin_url,
        data: data,
        beforeSend: function () {
            // $('.product-variation-structure-loading').fadeIn();
        },
        success: function (response) {
            if (response) {
                locations = JSON.parse(response);
                initMap();
            }
        },
        error: function (response) {},
    });

    // Initialize and add the map
    function initMap() {
        const mapID = document.getElementById('find-your-local-map');

        if (mapID) {
            const mapContainer = $('#find-your-local-map');
            pinIcon = mapContainer.attr('data-pin');

            const newZealandCenterPoint = {
                lat: -40.9248354,
                lng: 175.0039087,
            };

            //Center Map Point
            map = new google.maps.Map(mapID, {
                zoom: 5,
                center: newZealandCenterPoint,
                mapId: 'a6e5997fec278057',
                // styles: [
                //     {
                //         "featureType": "poi",
                //         "stylers": [
                //             { "visibility": "off" }
                //         ]
                //     }
                // ]
            });

            //Add Marker
            setMarkers(locations);

            //Open Dialog Info
            infowindow = new google.maps.InfoWindow();
        }
    }

    //Filter when choose a service
    $('.filter-by-services .forminator-checkbox').on('change', () => {
        let multiSelection = locations;

        // selectedServices
        selectedServices = [];
        $.each($('.filter-by-services-item:checked'), (key, el) => {
            selectedServices.push(el.value);
        });

        if (selectedServices.length) {
            multiSelection = locations.filter((element) => {
                return selectedServices.every((service) => {
                    return element.services.includes(service);
                });
            });
        }

        reloadMarkers(multiSelection);

        if (latitudeGlobal && longitudeGlobal) {
            getClosestStore(latitudeGlobal, longitudeGlobal);
        }
    });

    //Open Dialog Info
    function openDialogMarkerInfo(marker) {
        infowindow.setContent(marker.contentString);
        infowindow.open({
            anchor: marker,
            map,
            shouldFocus: false,
        });
    }

    //reload Markers
    function reloadMarkers(newMarkers) {
        for (var i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }

        markers = [];

        setMarkers(newMarkers);
    }

    //Set Markers
    function setMarkers(newMarkers) {
        newMarkers.forEach((element) => {
            // Create a content element for the AdvancedMarkerElement
            const content = document.createElement('div');
            content.className = 'custom-marker';

            // If you're using a custom icon, set it as the background image
            content.style.backgroundImage = `url(${pinIcon})`;
            content.style.backgroundSize = 'contain';
            content.style.width = '48px'; // Adjust as needed
            content.style.height = '48px'; // Adjust as needed

            // Create the AdvancedMarkerElement
            const marker = new AdvancedMarkerElement({
                map: map,
                position: {
                    lat: parseFloat(element.latitude),
                    lng: parseFloat(element.longitude),
                },
                title: element.title,
                content: content,
            });

            // Store additional data if needed
            marker.contentString = element.contentString;
            marker.services = element.services;

            markers.push(marker);
        });

        markers.forEach((marker, key) => {
            // Add event listener using the AdvancedMarkerElement's method
            marker.addListener('click', () => {
                openDialogMarkerInfo(marker, key);
            });
        });
    }

    //Filter Locations
    function filterLocations(lat1, lon1, lat2, lon2, unit = 'K') {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == 'K') {
            dist = dist * 1.609344;
        }
        if (unit == 'N') {
            dist = dist * 0.8684;
        }
        return dist;
    }
}
